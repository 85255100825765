@import "../../variables";
.faq-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: #242737;
  font-family: 'Poppins';
  // position: relative;
  color: #fff;
  // top: -350px;
  padding: 100px 0;
  .faq-container {
    max-width: $appmaxwidth;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    padding-bottom: 50px;
    > h5 {
      font-weight: 600;
      font-size: 28px;
      width: 90%;
      text-align: right; 
      margin: 0;
    }
    > p {
      font-size: 20px;
      width: 90%;
      text-align: right; 
    }
    .faqcard-wrapper {
      cursor: pointer;
      color: #fff;
      background: #191E3A;
      border: 1px solid #81BDF9;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 25px;
      display: flex;
      justify-content: center;
      text-align: left;
      margin: 20px 0;
      width: 90%;
      > .selector-container {
        width: 10%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        color: #8ccffb;

        > img {
          width: 30px;
          margin-top: 8px;
          margin-right: 2px;
        }
        .clicked {
          transform: rotate(180deg);
        }
      }
      > .faqs {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > h6 {
          font-size: 18px;
          margin: 0;
          font-weight: 400;
        }
        > p {
          font-size: 16px;
          margin: 0;
          margin-top: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 1292px) {
  // .faq-wrapper {
  //   top: 0;
  // }
}