.planCard-wrapper {
  width: 370px;
  margin: 40px;
  background: #191E3A;
  border: 1px solid #81BDF9;
  color: #fff;
  border-radius: 30px;
  > .title-container {
    display: flex;
    background-color: #81BDF9;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border: 1px solid #81BDF9;
    padding: 0 20px;
    > h3 {
      text-align: left;
      font-size: 30px;
      margin: 0;
    }
    h1{
      font-size: 26px;
    }
  }
  >.features{
    >ul{
      padding-left: 40px;
      padding-right: 20px;
      height: 280px;
    }
  }
  >.selector{
    width: 100%;
    text-align: center;
    > select {
      background: #2C3670;
      border: 1px solid #81BDF9;
      box-sizing: border-box;
      border-radius: 30px;
      font-size: 16px;
      padding: 15px;
      width: 90%;
      margin: 10px;
      outline: 0;
      color: #fff;
    }
  }
 
  > a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    > button {
      background: #A3D1FF;
      border: 1px solid #81BDF9;
      box-sizing: border-box;
      border-radius: 30px;
      color: #000;
      padding: 15px;
      width: 90%;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }

}
@media screen and (max-width: 760px) {
  .planCard-wrapper {
    margin: 10px;
  }
}
