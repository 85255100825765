.carousel {
    overflow: hidden;
    width: 1000px;
  }
  
  .inner {
    white-space: nowrap;
    transition: transform 0.3s;
  }
  
  .carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // height: 200px;
    
    color: #fff;
  }
  
  .prev-button{
    position: relative;
    top: 230px;
    left: 30px;
    z-index: 100;
    width: 50px;
    height: 50px;
    >img{
      width: 50px;
      height: 50px;
    }
  }
  .next-button{
    position: relative;
    bottom: 230px;
    right: -920px;
    z-index: 100;
    width: 50px;
    height: 50px;
    >img{
      width: 50px;
      height: 50px;
    }
  }
  .indicators {
    display: flex;
    justify-content: center;
  }
  
  .indicators > button {
    margin: 15px;
    margin-bottom: 0;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: #2C3670;
    border: none;
    outline: 0;
  }
  
  .indicators > button.active {
    background-color: #C6DDF4;
    color: #fff;
  }
  @media screen and (max-width: 1200px) {
    .prev-button, .next-button{
      display: none;
    }
  }