@import "../../variables";
.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 500;
  height: 100px;
  color: #fff;
  background-color: #242737;
  font-family: 'Poppins', sans-serif;
  > .navbar-container {
    width: 100%;
    max-width: $appmaxwidth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 80px;
    > div {
      display: flex;
      align-items: center;
      > a {
        color: #fff;
        padding: 0 25px;
        text-decoration: none;
        font-weight: 600;
        
      }
      > a:hover {
        transform: scale(1.2);
      }
      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 35px;
        background: #A3D1FF;
        box-shadow: 0px 4px 4px #2C405E;
        border-radius: 4px;
        > a {
          font-size: 14px;
          color: #000000;
          text-decoration: none;
          font-weight: bold;
        }
      }
    }
  }
}
.navbar-wrapper.active {
  display: none;
}

@media screen and (max-width: 1149px) {
  .navbar-wrapper {
    display: none;
  }
}
