.featuresCard-wrapper {
  width: 360px;
  height: 250px;
  margin: 20px;
  background: #191E3A;
  border: 1px solid #81BDF9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  >div{
    padding: 20px;
    display: flex;
    align-items: center;
    >img{
      width: 30%;
      margin-right: 20px;     
    }
    >div{
      width: 60%;
      >h5{
        font-size: 16px;
        margin: 0;
      }
      >p{
        font-size: 14px;
        white-space: break-spaces;
      }
      a{
        color: #fff;
        text-decoration: none;
        width: 50px;
        font-size: 14px;
        white-space: pre-wrap; /* css-3 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
        
      }
    }
  }
}
