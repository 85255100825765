@import "../../variables";
.footer-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  color: #fff;
  >.footer-content {
    // max-width: $appmaxwidth;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #242737;
    >.top{
      max-width: $appmaxwidth;
      display: flex;
      width: 100%;
      justify-content: space-between;
     >div{
      width: 30%;
       padding: 20px;
       >p{
         text-align: justify;
         white-space: break-spaces;
       }
       >a{
        text-decoration: none;
        color: #fff;
      }
       >div{
         display: flex;
         align-items: center;
         margin-left: 20px;
         img{
           width: 60px;
           margin-right: 20px;
         }
         >p{
          >a{
            text-decoration: none;
            color: #fff;
          }
         }
       
       }
     }
     .box2{
       h6{
         font-size: 16px;
         margin-bottom: 0;
       }
     }
    }
    >.middle {
      max-width: $appmaxwidth;
      background: #242737;
      display: flex;
      width: 100%;
      padding: 20px;
      
      >div{
        display: flex;
        align-items: center;
        padding-left: 40px;
        img{
          width: 60px;
          margin-right: 20px;
        }
      }
    }
    
  }
  >.bottom {
    background: #317FCC;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 70px;
    >img{
      width: 30px;
    }
    >p{
      margin-top: 10px;
      margin-left: 10px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 919px) {
  .footer-wrapper {
    .top {
     flex-direction: column;
     >div{
       width: 90% !important;
     }
     >.box1{
      > p{
       padding-left: 40px;
      }      
    }
     >.box2{
       >h6, p, a{
        padding-left: 40px;
       }      
     }
     >.box3{
      > p{
       padding-left: 20px;
      }      
    }
     
    }
  }
}
