@import "../../variables";
.tos-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 100px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 80px;
  color: #dbd3c5;
  > .tos-container {
    width: 80%;
    max-width: $appmaxwidth;
    height: 100%;
    h4 {
      font-size: 28px;
    }
    > p {
      font-size: 18px;
    }
    a {
      text-decoration: none;
      color: #dbd3c5;
    }
  }
}

