@import "../../variables";
.home-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 100px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 80px;
  > .home-container {
    max-width: $appmaxwidth;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 100px 0;
      >.left{
        width: 50%;
        text-align: center;
        >img{
          width: 90%;
        }
      }
      >.right{
        width: 50%;
        text-align: left;
        >h1{
          padding-left: 20px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 40px;
          line-height: 61px;
          color: #fff;
          >span{
            font-weight: lighter;
          }
        }
        >.button-container{
          display: flex;
          padding-left: 20px;         
          >button{
            padding: 15px;
            border-radius: 5px;
            border: 1px solid #A3D1FF;
            >a{
              text-decoration: none;
              font-weight: 600;
              font-size: 16px;
              >span{
                font-weight: 100;
              }
            }
          }
          >.button1{
            background: #A3D1FF;
            margin-right: 20px;
            >a{
              color: #000;
            }
          }
          >.button2{
            background-color: transparent;
            >a{
              color: #fff;
            }           
          }
        }
      }
    
  }
}
@media screen and (max-width: 1000px) {
  .home-wrapper {
    > .home-container {
      flex-direction: column;
      >.left, .right{
        width: 90%;
        text-align: center;
        >.button-container{
          justify-content: center;
          margin-bottom: 50px;
        }
      }
    }
  }
}
@media screen and (max-width: 561px) {
  .home-wrapper {
    > .home-container {
      > .right{
        >.button-container{
          flex-direction: column;
          align-items: center;
          >.button1{
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}