@import "../../variables";
.services-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Poppins';
  padding: 50px 0;
  padding-bottom: 0;
  > .services-container {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    >.content{
        display: flex;
        width: 100%;
        .left{
            width: 40%;
            >h5{
                font-weight: 600;
                font-size: 28px;
                text-align: left;  
                margin: 0;
               }
            >p{
                font-size: 20px;
            }
        }
        >.right{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 60%;
            >div{
                display: flex;
                background: #191E3A;
                border: 3px solid #5BA7F2;
                box-sizing: border-box;
                border-radius: 45px;
                padding: 30px;
                align-items: center;
                margin-bottom: 20px;
                justify-content: space-between;
                h3{
                    font-size: 24px;
                    margin: 0;
                }
                a{
                    text-decoration: none;
                    color: #fff;
                    white-space: pre-wrap; /* css-3 */
                    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
                    white-space: -pre-wrap; /* Opera 4-6 */
                    white-space: -o-pre-wrap; /* Opera 7 */
                    word-wrap: break-word; /* Internet Explorer 5.5+ */
                }
                >img{
                    width: 80px;
                    margin-left: 20px;
                }
            }
            >.box1{
                width: 500px;
            }
            >.box2{
                width: 600px;
            }
            >.box3{
                width: 700px;
            }
        }
    }
    >img{
        width: 50%;
        margin-bottom: -200px;
        margin-top: -150px;
        z-index: 100;
    }
 
}
}
@media screen and (max-width: 1427px) {
    .services-wrapper {
        > .services-container {
          >.content{
              display: flex;
              flex-direction: column;
              .left{
                  width: 100%;
                  text-align: center;
                  margin-bottom: 40px;
                  >h5{
                      text-align: center;
                  }
              }
              >.right{
                  width: 100%;
                  
              }
          }
          >img{
            width: 50%;
            margin-bottom: -100px;
            margin-top: -100px;
            top: 0;
        }
        }
    }
}
@media screen and (max-width: 1140px) {
    .services-wrapper {
        > .services-container {
          >img{
            width: 50%;
            margin-bottom: 0;
            margin-top: 0;
            top: 0;
        }
        }
    }
}
@media screen and (max-width: 870px) {
    .services-wrapper {
        > .services-container {
          >.content{
              >.right{
                  width: 100%;
                  align-items: center;
                >.box1{
                    width: 90%;
                }
                >.box2{
                    width: 90%;
                }
                >.box3{
                    width: 90%;
                    >div{
                        max-width: calc(100% - 100px );
                    }
                }
              }
          }
        }
    }
}