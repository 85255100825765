@import "../../variables";
.plans-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;  
  font-family: 'Poppins'; 
  .plans-container {
    max-width: $appmaxwidth;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    >h5{
      font-weight: 600;
      font-size: 28px;
      text-align: center; 
      margin-bottom: 20px; 
     }
    .tabs-container {
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #242737;
      > button {
        text-align: center;
        font-size: 14px;
        width: 150px;
        height: 40px;
        color: #fff;
        border: none;
        outline: 0;
        background: #242737;
        cursor: pointer;
        font-weight: 600;
        border-radius: 15px;
      }
      > .selected {
        background: #81BDF9;
      }
    }
    > .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    > p {
      font-size: 14px;
    }
  }
}

