@import "../../variables";
.reviews-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: #FFFFFF;
    font-family: 'Poppins';
    padding: 50px 0;
    .reviews-container{
        max-width: $appmaxwidth;
        width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .reviewCard-wrapper{
            width: 800px;
            border-radius: 30px;
            background-color: #A3D1FF;
            .reviewCard-container{
                padding: 20px;
                display: flex;
                color: #000;
                align-items: center;
                height: 100%;
                >.left{
                    width: 40%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    >h1{
                        font-size: 90px;
                        font-weight: 600;
                        margin-right: 20px;
                        margin: 20px;
                        >span{
                            font-size: 20px;
                        }
                    }
                }
                >.right{
                    width: 60%;       
                    p{
                        font-family: 'Poppins';
                        font-style: italic;
                        font-weight: 400;
                        font-size: 20px; 
                        white-space: break-spaces;     
                    }
                    .name{
                        font-size: 18px;
                        text-align: right;
                    }
                }
            }
        }
     
    }
}
@media screen and (max-width: 650px) {
    .reviewCard-wrapper{
        width: 100%;
        >  .reviewCard-container{
            flex-direction: column;
            >.left{
                width: 100%;
            }
            >.right{
                width: 100%;
            }
        }
    }
}