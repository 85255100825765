@import "../../variables";
.features-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 20px 0;
  color: #FFFFFF;
  font-family: 'Poppins';
  background: #242737;
  padding-bottom: 50px;
  > .features-container {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   >h5{
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    text-align: center;  
   }
    > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
